import React, { useState } from "react";
import queryString from 'query-string'
import { centsToDollars } from "../components/Checkout/sharedFuncs";
import { useGetSiteSettings } from "../_shared/hooks/useGetSiteSettings";

// For more information, see SBM-010:
// https://docs.google.com/presentation/d/1NQ832NADTCyPSBgBI6mu9cQn-eZovq7oWpfJDwzoIs8/edit#slide=id.g277e29d6e9d_0_5

const hydrateFromMemory = (varName) => {
  if (typeof window == `undefined`) {
    return {}
  }

  console.log ("[hydrateFromMemory] localStorage.getItem(varName): ", localStorage.getItem(varName) , " varName: ", varName)
  const val = localStorage.getItem(varName)
  console.log('[hydrateFromMemory] "' + varName + '" value: "' + val + '"')
  if (val == 'undefined' || !val) {
    return null
  }

  const memory1 = JSON.parse(val)
  console.log ("[hydrateFromMemory] memory1: ", memory1)
  
  return memory1
}

export const DiscountContext = React.createContext([])

// example url: www.ticketingLink.com?scd=aff123&dco=test1234&dt=10
const DiscountProvider = ({ children }) => {
  const { priceInflateInCents } = useGetSiteSettings()
  const paramNames = {
    discountCode: 'dco',        // this is the actual coupon code that is passed to the ticket system (e.g. Eventbrite as ?discount=)
    discountAmount: 'dt',       // the amount (in cents) to display on the website. (note: this is for this website's display only, it won't be passed through to Eventbrite.)
    sourceCode: 'scd'           // "source code": the marketing channel origin (this is "passed through" to ticketing systems like Eventbrite as "?aff="")
  }
  
  const [discountAmount, setDiscountAmount]   = useState(hydrateFromMemory(paramNames.discountAmount))
  const [discountCode, setDiscountCode]       = useState(hydrateFromMemory(paramNames.discountCode))
  const [sourceCode, setSourceCode]           = useState(hydrateFromMemory(paramNames.sourceCode))

  console.group('Discount URL params [#la2]:')
  console.log('discountAmount: ', discountAmount)
  console.log('discountCode: ', discountCode)
  console.log('sourceCode: ', sourceCode)
  console.groupEnd()

  // extract url params (like 'couponCode' and 'discountAmount') and "process" them (i.e. put them into localstorage & into provider.)
  const processUrlParams = (search) => {
    setParamFromURL(search, paramNames.discountCode, setDiscountCode)
    // const extraProcessing = (val) => parseFloat(val)
    setParamFromURL(search, paramNames.discountAmount, setDiscountAmount, parseInt)
    // setParamFromURL(search, paramNames.sourceCode, setSourceCode)
    processScdParam(search, setSourceCode)

  }

  // reset / remove all parameters (used for testing)
  const removeAllParams = () => {
    localStorage.removeItem(paramNames.discountCode)
    localStorage.removeItem(paramNames.discountAmount)
    localStorage.removeItem(paramNames.sourceCode)
    
    alert ('all params have been removed from app memory')
  }

  // gets the ?scd= from the url and adds it to the "scd chain" (to make a "compound scd")
  // learn about compound SCDs here: https://docs.google.com/presentation/d/1NQ832NADTCyPSBgBI6mu9cQn-eZovq7oWpfJDwzoIs8/edit#slide=id.g2c9d28ebdcb_0_0
  const processScdParam = (search, setSourceCode) => {
    console.log( "sourceCode [er4]: ", sourceCode)
    const paramName = paramNames.sourceCode
    // get new SCD
    let newScd = removeDotIfPresent(queryString.parse(search)[paramName])

    // todo: add code to remove all "." from an SCDs left? as this might accidentally be added in (by Mailchimp for example) and cause issues?

    // Remove any first "." characters (note: this may come from an email provider that ahs basic string manipulation tools and can't concatenate SCDs together correctly)
    console.log( "newScd [er4]: ", newScd)
    if (!newScd) { return }

    // split the existing scd
    // let curScd = localStorage.getItem(paramName)
    // curScd = curScd.trim()
    // console.log( "curScd [er4]: ", curScd)
    // check for the scd first (only check the last scd)

    // uncomment this to reset the scd to null
    // setParamInMemory(paramNames.sourceCode, null, setSourceCode); //return ;   
    
    let newCompoundScd = ''
    // check if there's already an SCD

    if (sourceCode) {
      // check if newScd is duplicated (but only at the end of sourceCode).
      // this is to prevent SCDs like: "mcec.GORGn15" (for example) being added to the chain a second and third time (if that scd is already *at the end* of the compoundSCD.)
      const isDuplicate = stringEndsWith(sourceCode, newScd)
      if (isDuplicate) {
        console.log('[ea1] sourceCode: ', sourceCode)
        console.log('[ea1] newScd isDuplicate: ', isDuplicate)
        // do no add the newScd
        
        return
      }

      const scdParts = sourceCode.split(".")
      console.log( "scdParts [er4]: ", scdParts)

      if (lastElementIsString(scdParts, newScd)) {
        return
      }

      // append to end of chain
      // console.log( "apppending to scd [er4]: ", newScd)
      const spacer = "."
      newCompoundScd = sourceCode + spacer + newScd
    } else {
      newCompoundScd = newScd
    }
    
    setParamInMemory(paramNames.sourceCode, newCompoundScd, setSourceCode)
    console.log( "new scd [er4]: ", sourceCode)
  }

  function removeDotIfPresent(str) {
    if (!str) { return null }

    if (str.startsWith(".")) {
      return str.slice(1); // Remove the first character
    } else {
      return str; // Return the original string if it doesn't start with "."
    }
  }

  function lastElementIsString(arr, targetStr) {
    const lastElement = arr[arr.length - 1];
    console.log("lastElement [er4]: ", lastElement)
    if (lastElement == targetStr) {
      return true
    }
    return false;
  }

  // Extract param from URL and set it on the context & localStorage
  const setParamFromURL = (search, paramName, setStateFunc, customProcessingFunc) => {
    let newVal = queryString.parse(search)[paramName]
    if (newVal) {
      setParamInMemory(paramName, newVal, setStateFunc, customProcessingFunc)
      console.log('setParamFromURL(): paramName: ', paramName ,', newVal [la2]: ', newVal)
    }
  }
  
  const setParamInMemory = (paramName, newVal, setStateFunc, customProcessingFunc = null) => {
    console.group('setParamFromURL (' + paramName + ')')
    console.log('paramName:"' + paramName + '" [#er4]: ', newVal)
    // saveState('discountCode', newVal, setDiscountCode)

    // apply custom function (ussually something like parseInt())
    if (customProcessingFunc) {
      console.log('custom Processing function running')
      newVal = customProcessingFunc (newVal)
    }
    console.log('newVal [#er4]: ', newVal)

    // set the value:
    localStorage.setItem(paramName, JSON.stringify(newVal))
    setStateFunc(newVal)
    console.groupEnd()
  }

  // this prevents discountAmount from being more than ss.priceInflateInCents #[fk9] - see: SBM-020-ss2
  // e.g. (original) discount Amount = 1500, but ss.priceInflateInCents = 1000, so return new discountAmount as 1000
  const calculateDiscount = (discountAmount) => {
    console.log('calculateDiscount [la3]: ', parseInt(discountAmount))
    console.log('priceInflateInCents [la3]: ', priceInflateInCents)
    if (discountAmount > priceInflateInCents) {
      discountAmount = priceInflateInCents
    }
    let curDis = parseInt(discountAmount)

    return curDis
  }

  const discountAmountInt = calculateDiscount(discountAmount)

  // add parameters to the booking link url such as: ?aff and ?discount (passthrough params)
  const generateBookingUrl = ( url ) => {
    // testUrl='https://www.ticketingLink.com?scd=$source_code_placeholder&dco=$discount_code'
    // const testUrl='https://www.ticketingLink.com'
    console.log('sourceCode [er6]: ', sourceCode)
    

    // aff= replacement problem:
    //  if the ?aff= has a "." in it, eventbrite will replace the entire ?aff= with "oddtdtcreator" - strange behaviour.
    // try this problem for yourself with this url:
    // https://www.eventbrite.com.au/e/79-great-ocean-rd-12-apostles-adventure-super-low-price-tickets-1104158470709?aff=aff1.aff2
    // so we use "-" instead of ".":

    // Transform sourceCode: first replace "-" with "err742", then replace "." with "-"
    const fixedScd = (!sourceCode || sourceCode === '') ? 'dw1' : sourceCode.replace(/-/g, 'err742').replace(/\./g, '-')

    url = addParameterToUrl(url, "aff", fixedScd)
    console.log('url [er6]: ', url)
    url = addParameterToUrl(url, "discount", discountCode)

    // if (sourceCode) {
    //   urlObj.searchParams.set("aff", sourceCode)  
    // } else {
    //   // note: the contentful ticketing url may have had a query parm "baked" into the url, in this case, we need to remove it.
    //   urlObj.searchParams.delete("aff")
    // }
    
    // url = urlObj.toString()
    
    console.group('url builder [#df5]:')
    console.log('discountCode: ', discountCode)
    console.log('scd: ', sourceCode)
    console.log('new url [#fg6]: ', url)
    console.groupEnd()

    return url
  }

  function addParameterToUrl(url, paramName, paramValue) {
    console.log('addParameterToUrl [er6]: ', url, paramName, paramValue)
    const urlObj = new URL(url)
    if (paramValue) {
      urlObj.searchParams.set(paramName, paramValue)
    } else {
      // note: the contentful ticketing url may have had a query parm "baked" into the url (when provided by the author),
      // so delete the param just in case it exists and isn't set.
      urlObj.searchParams.delete(paramName)
    }
  
    return urlObj.toString()
  }

  const discountAmountAsString = centsToDollars(discountAmountInt)

  return (
    <DiscountContext.Provider value={{
      discountAmount: discountAmountInt,
      discountAmountAsString,
      discountCode,
      sourceCode,
      processUrlParams,
      generateBookingUrl,
      removeAllParams
    }}>
      {children}
    </DiscountContext.Provider>
  )
}

export default DiscountProvider

function stringEndsWith(mainString, endingString) {
  const stringLength = mainString.length
  const endingLength = endingString.length
  const potentialEnding = mainString.slice(stringLength - endingLength)
  return potentialEnding === endingString
}